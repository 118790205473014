/**
 * Created by alejandro on 6/09/16.
 */
(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('Timeline', Timeline);

    Timeline.$inject = ['$sce', 'EventCodeTranslator', 'GOOGLE_STATIC_MAPS_API'];

    function Timeline($sce, EventCodeTranslator, GOOGLE_STATIC_MAPS_API) {

        var CLIENT_SIDE = 'right';
        var AGENT_SIDE = 'left';

        var ERROR_BADGE = 'glyphicon glyphicon-exclamation-sign';
        var INFO_BADGE = 'glyphicon glyphicon-info-sign';

        var PHOTO_BADGE_ICON = 'glyphicon glyphicon-picture';
        var SKETCH_BADGE_ICON = 'glyphicon glyphicon-pencil';
        var URL_BADGE_ICON = 'glyphicon glyphicon-link';
        var LOCATION_BADGE_ICON = 'glyphicon glyphicon-map-marker';
        var DOCUMENT_BADGE_ICON = 'glyphicon glyphicon-paperclip';
        var DELETED_BADGE_ICON = 'glyphicon glyphicon-remove';

        var service = {
            generateTimelineList: generateTimelineList
        };

        return service;

        function setBadgeFromCode(timelineElement, code) {
            setBadge(timelineElement,
                EventCodeTranslator.isErrorEvent(code) ? 'warning' : 'success',
                EventCodeTranslator.isErrorEvent(code) ? ERROR_BADGE : INFO_BADGE);
        }

        function setBadge(timelineElement, badgeClass, badgeIcon) {
            timelineElement.badgeClass = badgeClass;
            timelineElement.badgeIconClass = badgeIcon;
        }

        function eventToTimelineElement(event) {
            var timelineElement = {
                side: (event.source === "AGENT") ? AGENT_SIDE : CLIENT_SIDE,
                timestamp: event.timestamp,
                msg: EventCodeTranslator.getMsg(event.code),
                translateValues: event.params
            };
            setBadgeFromCode(timelineElement, event.code);
            return timelineElement;
        }

        function itemToTimelineElement(item) {
            var timelineElement = {
                title: item.channel,
                timestamp: item.creationDate
            };
            switch (item.source) {
                case "CLIENT":
                    timelineElement.badgeClass = 'warning';
                    timelineElement.side = CLIENT_SIDE;
                    break;
                case "AGENT":
                    timelineElement.badgeClass = 'info';
                    timelineElement.side = AGENT_SIDE;
                    break;
            }
            if (item.content) {
                switch (item.type) {
                    case "PHOTO":
                        timelineElement.photoContent = $sce.trustAsHtml('<img src="' + item.content.url + '"/>');
                        timelineElement.badgeIconClass = PHOTO_BADGE_ICON;
                        timelineElement.photoUrl = item.content.url;
                        break;
                    case "SKETCH":
                        timelineElement.photoContent = $sce.trustAsHtml('<img src="' + item.content.url + '"/>');
                        timelineElement.badgeIconClass = SKETCH_BADGE_ICON;
                        timelineElement.photoUrl = item.content.url;
                        break;
                    case "LOCATION":
                        timelineElement.content = $sce.trustAsHtml('<a class="thumbnail" target="_blank" href="https://www.google.com/maps?q=' +
                            item.content.latitude + ',' + item.content.longitude +
                            '"><img src="https://maps.googleapis.com/maps/api/staticmap?center=' +
                            item.content.latitude + ',' + item.content.longitude +
                            '&amp;zoom=16&amp;size=600x300&amp;maptype=roadmap&amp;markers=color:blue%7Clabel:S%7C' +
                            item.content.latitude + ',' + item.content.longitude + '&amp;key=' + GOOGLE_STATIC_MAPS_API + '" width="100%" /></a>');
                        if (item.content.accuracy > 0) {
                            timelineElement.msg = 'superChannelApp.session.accuracy';
                            timelineElement.translateValues = {accuracy: item.content.accuracy};
                        }
                        timelineElement.badgeIconClass = LOCATION_BADGE_ICON;
                        break;
                    case "URL":
                        timelineElement.content = $sce.trustAsHtml('<a target="_blank" href="' + item.content.url + '">' + item.content.url + '</a>');
                        timelineElement.badgeIconClass = URL_BADGE_ICON;
                        break;
                    case "DOCUMENT":
                        timelineElement.content = $sce.trustAsHtml('<a target="_blank" href="' + item.content.url + '">' +
                            '<i class="' + DOCUMENT_BADGE_ICON + '"></i><span> ' + item.content.fileName + '</span></a>');
                        timelineElement.badgeIconClass = DOCUMENT_BADGE_ICON;
                        break;
                }
            } else {
                timelineElement.msg = 'superChannelApp.item.deletedContent';
                timelineElement.badgeIconClass = DELETED_BADGE_ICON;
            }
            return timelineElement;
        }

        function generateTimelineList(items, events) {
            var timelineList = [];
            for (var x = 0; x < items.length; x++) {
                timelineList.unshift(itemToTimelineElement(items[x]));
            }
            for (var i = 0; i < events.length; i++) {
                timelineList.unshift(eventToTimelineElement(events[i]));
            }
            timelineList.sort(function (elem1, elem2) {
                var date1 = new Date(elem1.timestamp).getTime();
                var date2 = new Date(elem2.timestamp).getTime();
                return (date1 > date2) ? -1 : (date1 < date2) ? 1 : 0;
            });
            return timelineList;
        }

    }
})();
