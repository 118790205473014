(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('canvas', {
                url: '/{tenant}/canvas/{photo}',
                data: {
                    authorities: []
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/components/canvasViewer/canvas-viewer.html',
                        controller: 'CanvasViewerController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();
