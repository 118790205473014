(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('CanvasViewerController', CanvasViewerController);

    CanvasViewerController.$inject = ['$stateParams'];

    function CanvasViewerController($stateParams) {
        var vm = this;

        vm.fileInput = $stateParams.photo;
        vm.options = {
            controls : {
                toolbar : true,
                fit : 'page'
            }
        };
    }
})();
