(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('SessionController', SessionController);

    SessionController.$inject = ['$rootScope', '$scope', '$state', '$translate', 'Session', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'PaginationUtil'];

    function SessionController($rootScope, $scope, $state, $translate, Session, ParseLinks, AlertService, pagingParams, paginationConstants, PaginationUtil) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.openCalendar = openCalendar;
        vm.search = search;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchParams = (pagingParams.search) ? pagingParams.search : null;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.datePickerButtonBar = {
            show: true,
            today: {
                show: true,
                text: $translate.instant('global.datePickerButtons.today')
            },
            clear: {
                show: true,
                text: $translate.instant('global.datePickerButtons.clear')
            },
            date: {
                show: true,
                text: $translate.instant('global.datePickerButtons.date')
            },
            time: {
                show: true,
                text: $translate.instant('global.datePickerButtons.time')
            },
            close: {
                show: true,
                text: $translate.instant('global.datePickerButtons.close')
            }
        };

        loadAll();

        function loadAll() {
            var currentSearch = (vm.searchParams) ? vm.searchParams : {};
            Session.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sessionId: (currentSearch.sessionId === "") ? null : currentSearch.sessionId,
                agent: (currentSearch.agent === "") ? null : currentSearch.agent,
                target: (currentSearch.target === "") ? null : currentSearch.target,
                start: (currentSearch.start === "") ? null : currentSearch.start,
                end: (currentSearch.end === "") ? null : currentSearch.end,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: PaginationUtil.joinSearch(vm.searchParams),
                tenant: $state.params.tenant
            });
        }

        function search() {
            vm.page = 1;
            transition();
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            vm.datePickerButtonBar.today.text = $translate.instant('global.datePickerButtons.today');
            vm.datePickerButtonBar.clear.text = $translate.instant('global.datePickerButtons.clear');
            vm.datePickerButtonBar.date.text = $translate.instant('global.datePickerButtons.date');
            vm.datePickerButtonBar.time.text = $translate.instant('global.datePickerButtons.time');
            vm.datePickerButtonBar.close.text = $translate.instant('global.datePickerButtons.close');
        });
    }
})();
