(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$window', '$log', 'LANGUAGES'];

    function translationStorageProvider($cookies, $window, $log, LANGUAGES) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            var browserLang = ($window.navigator.language || $window.navigator.userLanguage).split('-')[0];
            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1 && LANGUAGES.indexOf(browserLang) !== -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to language "' + browserLang + '"');
                $cookies.putObject(name, browserLang);
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }
    }
})();
