(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['$resource', 'TenantService'];

    function PasswordResetInit($resource, TenantService) {
        var service = $resource('api/account/reset_password/init', {}, {
            'save': {
                method: 'POST',
                headers: { 'X-TenantID': TenantService.getFromUrl() }
            }
        });
        return service;
    }
})();
