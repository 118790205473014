(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('ExpiredPasswordController', ExpiredPasswordController);

    ExpiredPasswordController.$inject = ['Auth', '$uibModalInstance', 'credentials'];

    function ExpiredPasswordController(Auth, $uibModalInstance, credentials) {
        var vm = this;

        vm.clear = clear;
        vm.changeExpiredPassword = changeExpiredPassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.account = null;
        vm.samePassword = null;
        vm.close = close;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function close () {
            vm.doNotMatch = vm.samePassword = vm.error = null;
        }

        function changeExpiredPassword() {
            if (vm.password !== vm.confirmPassword) {
                vm.error = vm.success = vm.samePassword = null;
                vm.doNotMatch = 'ERROR';
            } else if (vm.password === credentials.password) {
                vm.error = vm.success = vm.doNotMatch = null;
                vm.samePassword = 'ERROR';
            } else {
                vm.doNotMatch = null;
                credentials.newPassword = vm.password;
                Auth.changeExpiredPassword(credentials).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    credentials.password = credentials.newPassword;
                    $uibModalInstance.close(credentials);
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
