(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('Captcha', Captcha);

    Captcha.$inject = ['$resource', 'TenantService'];

    function Captcha($resource, TenantService) {
        var resourceUrl = 'api/settings/captcha';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                headers: {'X-TenantID': TenantService.getFromUrl()},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
