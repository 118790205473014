(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .directive('startsWith', function() {
            return {
                require: 'ngModel',
                link: function(scope, element, attr, mCtrl) {
                    function validateStartsWith(value) {
                        mCtrl.$setValidity('startswith', (value.length === 0) || !attr.startsWith || (value.indexOf(attr.startsWith) === 0));
                        return value;
                    }
                    return mCtrl.$parsers.push(validateStartsWith);
                }
            }
        });
})();
