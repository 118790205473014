(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('Statistics', Statistics);

    Statistics.$inject = ['$resource'];

    function Statistics ($resource) {
        var resourceUrl = 'api/statistics';

        var service = $resource(resourceUrl, {}, {
            'agents': {
                method: 'GET',
                isArray: true,
                url: resourceUrl + '/agents'
            },
            'chart': {
                method: 'POST'
            }
        });

        return service;
    }
})();
