(function () {
        'use strict';

        angular
            .module('superChannelApp')
            .controller('ItemDialogController', ItemDialogController);

        ItemDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'sessionValues', 'Item', 'AlertService', 'DataUtils', 'FileRestrictions', 'VideoInitiate', 'ConnectParticipant', 'Session'];

        function ItemDialogController($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, entity, sessionValues, Item, AlertService, DataUtils, FileRestrictions, VideoInitiate, ConnectParticipant, Session) {
            var vm = this;
            var alertError = [];
            AlertService.clear();

            var validUrlPattern = new RegExp(
                "^" +
                // protocol identifier
                "(?:(?:https?|ftp)://)" +
                // user:pass authentication
                "(?:\\S+(?::\\S*)?@)?" +
                "(?:" +
                // IP address exclusion
                // private & local networks
                "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
                "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
                "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
                // IP address dotted notation octets
                // excludes loopback network 0.0.0.0
                // excludes reserved space >= 224.0.0.0
                // excludes network & broacast addresses
                // (first & last IP address of each class)
                "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
                "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
                "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
                "|" +
                // host name
                "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
                // domain name
                "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
                // TLD identifier
                "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
                // TLD may end with dot
                "\\.?" +
                ")" +
                // port number
                "(?::\\d{2,5})?" +
                // resource path
                "(?:[/?#]\\S*)?" +
                "$", "i"
            );

            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            });

            vm.item = entity;
            vm.sessionId = $stateParams.id;
            vm.clearPad = clearPad;
            vm.save = save;
            vm.redPen = redPen;
            vm.blackPen = blackPen;
            vm.clear = clear;
            var BYTES_IN_MB = 1048576;
            var dataURL;
            vm.photoLibraryTitles = sessionValues.photoTitles;
            vm.documentLibraryTitles = sessionValues.documentTitles;
            vm.urlLibrary = sessionValues.urlLibrary;

            vm.validUrlPattern = validUrlPattern;

            vm.showButtons = true;
            vm.isLocal = false;
            vm.isContentLibrary = false;
            vm.localUpload = localUpload;
            vm.contentLibrary = contentLibrary;

            function localUpload() {
                vm.isLocal = true;
                vm.isContentLibrary = false;
                vm.showButtons = false;
            }

            function contentLibrary() {
                vm.isLocal = false;
                vm.isContentLibrary = true;
                vm.showButtons = false;
            }

            $scope.toggle = true;
            $scope.$watch('toggle', function () {
                $scope.controlRoom = $scope.toggle ? 'Open Video Room' : 'Close Video Room';
            })

            function clear() {
                AlertService.clear();
                $rootScope.dialogOpen = false;
                $uibModalInstance.dismiss('cancel');
                vm.isLocal = false;
                vm.isContentLibrary = false;
                vm.showButtons = true;
            }

            function blackPen() {
                vm.isBlack = true;
                vm.isRed = false;
                signaturePad.penColor = "rgb(0, 0, 0)";
            }

            function redPen() {
                vm.isBlack = false;
                vm.isRed = true;
                signaturePad.penColor = "rgb(255, 0, 0)";
            }

            function clearPad() {
                signaturePad.clear();
            }

            function isEmpty() {
                return signaturePad.isEmpty();
            }

            function save() {
                var error = false;
                for (var i = 0; i < alertError.length; i++) {
                    AlertService.closeAlert(alertError[i].id);
                }
                alertError = [];
                vm.isSaving = true;
                if (vm.item.type === "URL") {
                    var urlInput = vm.item.content.url;
                    if (!urlInput.startsWith("http")) {
                        vm.item.content.url = "https://" + urlInput;
                    }
                    if (!validUrlPattern.test(vm.item.content.url)) {
                        alertError.push(AlertService.error("superChannelApp.item.url.help.url"));
                        error = true;
                    }
                }


                if (vm.item.type === "SKETCH") {
                    dataURL = signaturePad.toDataURL("image/jpeg");
                    vm.item.content = {};
                    vm.item.content.binaryData = dataURL;
                }


                if (vm.item.type === "VIDEO") {
                    vm.liveVideoActive ? stopLiveVideo() : startLiveVideo();
                } else {
                    var file = vm.item.content.binaryData;


                    if (file) {
                        var MAX_FILE_SIZE = sessionValues.maxFileSizeInMB.max * BYTES_IN_MB;
                        if (DataUtils.byteSize(file) > MAX_FILE_SIZE) {
                            alertError.push(AlertService.error("error.maxFileSize", {maxSize: MAX_FILE_SIZE / BYTES_IN_MB}));
                            error = true;
                        }
                        if (!DataUtils.getDataType(file).length > 0) {
                            alertError.push(AlertService.error("error.notValid"));
                            error = true;
                        }
                        if (vm.item.type === "PHOTO") {
                            if (sessionValues.photoFileTypesArray.indexOf((DataUtils.getDataType(file))) < 0) {
                                alertError.push(AlertService.error("error.invalidFormat"));

                            }
                        }
                        if (vm.item.type === "DOCUMENT") {
                            if (sessionValues.documentFileTypesArray.indexOf((DataUtils.getDataType(file))) < 0) {
                                alertError.push(AlertService.error("error.invalidFormat"));
                                error = true;
                            }
                        }
                    }
                    if (!error) {
                        if (!file && (vm.item.type === "PHOTO" || vm.item.type === "DOCUMENT")) {
                            // Doing content copy
                            Item.copyContent({sessionId: vm.sessionId}, {
                                    contentType: vm.item.type.toLowerCase(),
                                    filename: vm.item.content.key
                                },
                                onSaveSuccess, onSaveError);
                        } else if (vm.item.type !== "VIDEO") {
                            Item.save({sessionId: vm.sessionId}, vm.item, onSaveSuccess, onSaveError);
                        }
                    } else {
                        onSaveError();
                    }
                }

                function startLiveVideo() {
                    VideoInitiate.getVideoResource({
                        sessionId: vm.sessionId
                    }).$promise.then(function (token) {
                        var roomToken = token[0];
                        ConnectParticipant.connecting(roomToken);
                        vm.liveVideoActive = true;
                    }).catch(function (error) {
                        console.log('Error connecting to room', error);
                    });

                }

                function stopLiveVideo() {
                    vm.liveVideoActive = false;
                    ConnectParticipant.disconnectRoom();
                    clear();
                }

                function onSaveSuccess(result) {
                    AlertService.clear();
                    $rootScope.dialogOpen = false;
                    $uibModalInstance.close(result);
                    vm.isSaving = false;
                }

                function onSaveError() {
                    vm.isSaving = false;
                }
            }
        }
    }

)();
