"use strict";

(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .service('ConnectParticipant', ConnectParticipant);

    function ConnectParticipant() {
        this.connecting = connecting;
        this.disconnectRoom = disconnectRoom;
        var activeRoom;

        function connecting(token) {
            var Video = Twilio.Video;
            Video.createLocalTracks({
                audio: false,
                video: false
            }).then(function (localTracks) {
                return Video.connect(
                    token,
                    {
                        tracks: localTracks
                    }
                ).then(function (room) {
                    activeRoom = room;

                    room.on('participantDisconnected', function () {
                        document.getElementById("local-media-div").innerHTML = null;
                    });

                    localTracks.forEach(function (track) {
                        document
                            .getElementById("local-media-div")
                            .appendChild(track.attach());
                    });

                    room.participants.forEach(function (participant) {

                        participant.tracks.forEach(function (publication) {
                            if (publication.isSubscribed) {
                                document.getElementById("local-media-div").innerHTML = null;
                                document
                                    .getElementById("local-media-div")
                                    .appendChild(publication.track.attach());
                            }
                        });

                        participant.on("trackSubscribed", function (track) {
                            if (document.getElementById("local-media-div")) {
                                document.getElementById("local-media-div").innerHTML = null;
                                document
                                    .getElementById("local-media-div")
                                    .appendChild(track.attach());
                            }
                        });
                    });

                    room.on(
                        "participantConnected",
                        function (participant) {
                            document.getElementById("local-media-div").innerHTML = null;
                            participant.tracks.forEach(function (publication) {
                                if (publication.isSubscribed) {
                                    var track = publication.track;
                                    document
                                        .getElementById("local-media-div")
                                        .appendChild(track.attach());
                                }
                            });
                            participant.on("trackSubscribed", function (track) {
                                document.getElementById("local-media-div").innerHTML = null;
                                document
                                    .getElementById("local-media-div")
                                    .appendChild(track.attach());
                            });
                        },
                        function (error) {
                            console.error(
                                "Unable to connect to Room", error.message
                            );
                        }
                    );

                });
            }).catch(function (error) {
                console.log("Caught error while connecting to room", error);
            });
        }

        function disconnectRoom() {
            document.getElementById("local-media-div").innerHTML = null;
            activeRoom.localParticipant.tracks.forEach(function (publication) {
                var attachedElements = publication.track.detach();
                attachedElements.forEach(function (element) {
                    return element.remove();
                });
            });
            // To disconnect from a Room
            activeRoom.disconnect();
        }
    }
})();
