(function() {
    'use strict';

    angular
        .module('superChannelApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.router.state.events',
            'ui.select',
            'infinite-scroll',
            'angular-timeline',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'CanvasViewer'
        ])
        .config(['uiSelectConfig' ,function(uiSelectConfig) {
            uiSelectConfig.theme = 'bootstrap';
        }])
        .config(['$locationProvider', function ($locationProvider) {
            $locationProvider.hashPrefix('');
        }])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
