(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('SessionDialogController', SessionDialogController);

    SessionDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Session', 'AlertService', 'Principal'];

    function SessionDialogController($timeout, $scope, $uibModalInstance, entity, Session, AlertService, Principal) {
        var vm = this;

        vm.session = entity;
        vm.clear = clear;
        vm.save = save;
        vm.onChange = onChange;
        vm.alerts = AlertService.get();
        vm.selectedChannels = [];
        vm.isSaving = vm.invalidFormat = false;
        vm.sessionType = vm.session.target ? vm.session.target.toLowerCase() === "web" ? "web" : "sms" : "sms";

        //Placeholder to test external id
        vm.session.externalId = vm.session.externalId ? vm.session.externalId : null;

        Principal.identity().then(function (account) {
            vm.allowedChannels = account.allowedChannels;
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            channelPreselection();
        });


        function channelPreselection() {
            vm.session.selectedChannels.forEach(function (channel) {
                for (var i = 0; i < vm.allowedChannels.length; i++) {
                    if (vm.allowedChannels[i].name === channel) {
                        vm.selectedChannels[vm.allowedChannels[i].id] = true;
                    }
                }
            });
            if (vm.session.send && vm.session.send === "true") {
                save();
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function isPhoneNumberValid(phoneNumber) {
            return /^\+\d{10,13}$/.test(phoneNumber);
        }

        function onChange() {
            if (event.target.value.toString().toLowerCase() === 'web') {
                vm.session.target = 'web';
            } else {
                vm.session.target = "";
            }
        }

        function save() {
            vm.isSaving = true;
            if (vm.sessionType === 'sms') {
                if (!isPhoneNumberValid(vm.session.target)) {
                    if (vm.alerts.length <= 0) {
                        AlertService.error("superChannelApp.session.message.invalidformat");
                        vm.alerts = AlertService.get();
                    }
                    vm.invalidFormat = true;
                    vm.isSaving = false;
                    return;
                }
            }

            vm.session.channels = vm.allowedChannels.filter(function (elem) {
                return Object.keys(vm.selectedChannels).indexOf(elem.id.toString()) > -1 && vm.selectedChannels[elem.id.toString()]
            });
            Session.save(vm.session, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.session = result;
            $scope.$emit('superChannelApp:sessionUpdate', result);
            $uibModalInstance.close(result.id);
            vm.isSaving = vm.invalidFormat = false;
        }

        function onSaveError() {
            vm.isSaving = vm.invalidFormat = false;
        }

    }
})();
