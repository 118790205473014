(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('SessionExpirationController', SessionExpirationController);

    SessionExpirationController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'Session', 'VideoInitiate'];

    function SessionExpirationController($scope, $stateParams, $uibModalInstance, Session, VideoInitiate) {
        var vm = this;

        vm.sessionId = $stateParams.id;
        vm.clear = clear;
        vm.expire = expire;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function expire() {
            vm.isSaving = true;
            VideoInitiate.completeRoom({
                sessionId: vm.sessionId
            });
            Session.expiration({id: vm.sessionId}, {}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('superChannelApp:sessionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
