(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .directive('countdown', countdown);

    countdown.$inject = ['$interval'];

    function countdown ($interval) {
        // return the directive link function. (compile function not needed)
        return function(scope, element, attrs, $rootScope) {
            var endTime,
                stopTime,
                isExpired;

            // used to update the UI
            function updateTime() {
                var ms = endTime.getTime() - new Date().getTime();
                if (ms > 0) {
                    var min = Math.floor(ms/1000/60),
                        sec = Math.floor((ms/1000) % 60 );
                    sec= ('0' + sec).slice(-2);
                    var timeleft = min + ':' + sec;
                    element.text(timeleft);
                } else {
                    if (!isExpired) {
                        $interval.cancel(stopTime);
                        $rootScope.$broadcast('superChannelApp:sessionExpired');
                    }
                }
            }

            scope.$watch(attrs.isExpired, function() {
                isExpired = scope.$eval(attrs.isexpired);
            });

            scope.$watch(attrs.endTime, function() {
                endTime = scope.$eval(attrs.endtime);
                updateTime();
            });

            stopTime = $interval(updateTime, 1000);

            // listen on DOM destroy (removal) event, and cancel the next UI update
            // to prevent updating time after the DOM element was removed.
            element.on('$destroy', function() {
                $interval.cancel(stopTime);
            });
        };
    }

})();
