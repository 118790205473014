(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('SessionDetailController', SessionDetailController);

    SessionDetailController.$inject = ['$scope', '$rootScope', '$interval', 'previousState', 'entity', 'Timeline', 'Principal', '$state', '$uibModal', 'Session', 'AlertService', 'FileRestrictions', 'Item', '$q'];

    function SessionDetailController($scope, $rootScope, $interval, previousState, entity, Timeline, Principal, $state, $uibModal, Session, AlertService, FileRestrictions, Item) {
        var vm = this;
        var POOLING_TIME = 5000;
        var lastItem;
        var lastEvent;

        vm.session = entity;
        vm.previousState = previousState.name;
        vm.timelineList = Timeline.generateTimelineList(vm.session.items, vm.session.events);
        vm.itemCreationButtons = [];
        vm.createItem = createItem;
        lastItem = vm.session.items.slice().pop();
        lastEvent = vm.session.events.slice().pop();
        vm.expireSession = expireSession;
        vm.isExpired = (vm.session.end <= new Date());
        vm.dialogOpen = $rootScope.dialogOpen;

        var photoFileTypesArray = FileRestrictions.getPhotoFileTypes();
        var documentFileTypesArray = FileRestrictions.getDocumentFileTypes();
        var maxFileSizeInMB = FileRestrictions.getMaxFileSize();

        var photoLibraryTitles = Item.listContent({
            sessionId: vm.session.id,
            contentType: 'photo'
        });
        var documentLibraryTitles = Item.listContent({
            sessionId: vm.session.id,
            contentType: 'document'
        });
        var urlLibrary = Item.listContent({
            sessionId: vm.session.id,
            contentType: 'url'
        });

        vm.documentExtensions = '';
        vm.photoExtensions = '';

        documentFileTypesArray.$promise.then(function (types) {
            vm.documentExtensions = types.join(',');
        });
        photoFileTypesArray.$promise.then(function (types) {
            vm.photoExtensions = types.join(',');
        });

        vm.photoLibraryTitles = [];
        photoLibraryTitles.$promise.then(function (content) {
            vm.photoLibraryTitles = content;
        });

        vm.documentLibraryTitles = [];
        documentLibraryTitles.$promise.then(function (content) {
            vm.documentLibraryTitles = content;
        });

        vm.urlLibrary = [];
        urlLibrary.$promise.then(function (content) {
                vm.urlLibrary = content;
        });

        if (!vm.isExpired) {
            vm.updateTimeline = $interval(updateTimeline, POOLING_TIME);
        }

        Principal.identity().then(function (account) {
            for (var i = 0; i < account.allowedChannels.length; i++) {
                var button;
                switch (account.allowedChannels[i].itemContentType) {
                    case "PHOTO":
                        button = {
                            state: "session-detail.new-photo-item",
                            badge: "glyphicon glyphicon-picture"
                        };
                        break;
                    case "LOCATION":
                        button = {
                            state: "session-detail.new-location-item",
                            badge: "glyphicon glyphicon-map-marker"
                        };
                        break;
                    case "URL":
                        button = {
                            state: "session-detail.new-url-item",
                            badge: "glyphicon glyphicon-link"
                        };
                        break;
                    case "DOCUMENT":
                        button = {
                            state: "session-detail.new-document-item",
                            badge: "glyphicon glyphicon-paperclip"
                        };
                        break;
                    case "SKETCH":
                        button = {
                            state: "session-detail.new-document-item",
                            badge: "glyphicon glyphicon-pencil"
                        };
                        break;
                    case "VIDEO":
                        button = {
                            state: "session-detail.new-video-item",
                            badge: "glyphicon glyphicon-facetime-video"
                        };
                        break;
                }
                button.channel = account.allowedChannels[i];
                vm.itemCreationButtons.push(button);
            }
        });

        function updateTimeline() {
            Session.updates({
                id: vm.session.id,
                lastItem: lastItem ? lastItem.id : null,
                lastEvent: lastEvent ? lastEvent.id : null
            }, update, errorUpdate);

            function update(data) {
                var newElements = Timeline.generateTimelineList(data.items, data.events);
                newElements.reverse().forEach(function (element) {
                    vm.timelineList.unshift(element);
                });
                if (data.items.length > 0) {
                    AlertService.success("superChannelApp.session.newElement" + ((data.items.length > 1) ? 's' : ''), {param: data.items.length});
                }
                lastItem = (data.items.length > 0) ? data.items.pop() : lastItem;
                lastEvent = (data.events.length > 0) ? data.events.pop() : lastEvent;
            }

            function errorUpdate(error) {
                if (error.data && error.data.fieldErrors[0].message == 'expired') {
                    $state.reload();
                }
            }
        }

        function createItem(channel) {
            $rootScope.dialogOpen = true;
            vm.dialogOpen = true;
            $uibModal.open({
                templateUrl: 'app/entities/item/item-' + channel.itemContentType.toLowerCase() + '-dialog.html',
                controller: 'ItemDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            channel: channel.name,
                            type: channel.itemContentType,
                        };
                    },
                    sessionValues: function () {
                        return {
                            documentTitles: vm.documentLibraryTitles,
                            photoTitles: vm.photoLibraryTitles,
                            photoFileTypesArray: vm.photoExtensions,
                            documentFileTypesArray: vm.documentExtensions,
                            maxFileSizeInMB: maxFileSizeInMB,
                            urlLibrary: vm.urlLibrary
                        }
                    }
                }
            }).result.then(itemCreationSuccess, closeModal);
        }

        function expireSession() {
            $uibModal.open({
                templateUrl: 'app/entities/session/session-expiration-dialog.html',
                controller: 'SessionExpirationController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md'
            }).result.then(closeModal, closeModal);
        }

        function itemCreationSuccess() {
            AlertService.success("superChannelApp.item.created");
            closeModal();
        }

        function closeModal() {
            vm.dialogOpen = false;
            $state.go('session-detail', null, {reload: true});
        }

        var unsubscribe = $rootScope.$on('superChannelApp:sessionUpdate', function (event, result) {
            vm.session = result;
        });
        $scope.$on('$destroy', unsubscribe);
        $scope.$on('$destroy', function () {
            $interval.cancel(vm.updateTimeline);
        });
        $scope.$on('superChannelApp:sessionExpired', function () {
            Session.expiration({id: vm.session.id}, {}, function (result) {
                vm.session = result;
            });
        });
    }
})();


