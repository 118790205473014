/**
 * Created by alejandro on 6/09/16.
 */
(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('EventCodeTranslator', EventCodeTranslator);

    function EventCodeTranslator() {

        var service = {
            getMsg: getMsg,
            isErrorEvent: isErrorEvent
        };

        return service;

        function isErrorEvent(code) {
            return ((code + '')[0] === '3' || (code + '')[0] === '4');
        }

        function getMsg(code) {
            var msgName;
            switch (code) {
                //1xx: Client information
                case 101:
                    msgName = "clientLogin";
                    break;
                //2xx: Session information
                case 201:
                    msgName = "sessionCreated";
                    break;
                //3xx: Client error
                case 301:
                    msgName = "locationError";
                    break;
                case 203:
                    msgName = "videoSession";
                    break;
                //4xx: Session error
                //NO SESSION ERROR CODES YET.
            }
            return 'superChannelApp.event.msg.' + msgName;
        }
    }
})();
