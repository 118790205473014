(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('Item', Item);

    Item.$inject = ['$resource', 'DateUtils'];

    function Item($resource, DateUtils) {
        var resourceUrl = 'api/sessions/:sessionId/items/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            listContent: {
                url: 'api/sessions/:sessionId/items/library/:contentType',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            copyContent: {
                url: 'api/sessions/:sessionId/items/library/copy/',
                method: 'POST'
            }
        });
    }
})();
