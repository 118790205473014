(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('VideoInitiate', VideoInitiate);

    VideoInitiate.$inject = ['$resource'];

    function VideoInitiate($resource) {
        var resourceUrl = 'api/sessions/:sessionId/:option';

        return $resource(resourceUrl, {}, {
            getVideoResource: {
                method: 'GET',
                isArray: true,
                params: {option: 'video'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            },
            completeRoom: {
                method: 'GET',
                isArray: true,
                params: {option: 'close'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            }

        });
    }
})();
