(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('DocsController', DocsController);

    DocsController.$inject = ['API_URL'];
    function DocsController(API_URL) {
        var vm = this;

        vm.frameRef = "swagger-ui/index.html#"+encodeURIComponent(API_URL);
    }

})();
