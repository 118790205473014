(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('PaginationUtil', PaginationUtil);

    function PaginationUtil() {

        var service = {
            parseAscending: parseAscending,
            parsePage: parsePage,
            parsePredicate: parsePredicate,
            parseSearch: parseSearch,
            joinSearch: joinSearch,
            parseChannels: parseChannels
        };

        return service;

        function parseAscending(sort) {
            var sortArray = sort.split(',');
            if (sortArray.length > 1) {
                return sort.split(',').slice(-1)[0] === 'asc';
            } else {
                // default to true if no sort defined
                return true;
            }
        }

        // query params are strings, and need to be parsed
        function parsePage(page) {
            return parseInt(page);
        }

        // sort can be in the format `id,asc` or `id`
        function parsePredicate(sort) {
            var sortArray = sort.split(',');
            if (sortArray.length > 1) {
                sortArray.pop();
            }
            return sortArray.join(',');
        }

        function parseSearch(searchParams) {
            if (!searchParams) {
                return null;
            }
            var params = searchParams.split(',');
            return {
                sessionId: (params[0] === "") ? null : params[0],
                agent: (params[1] === "") ? null : params[1],
                target: (params[2] === "") ? null : params[2],
                start: (params[3] === "") ? null : new Date(params[3]),
                end: (params[4] === "") ? null : new Date(params[4])
            }
        }

        function joinSearch(searchParams) {
            if (!searchParams) {
                return null;
            } else {
                var query = ((searchParams.sessionId) ? searchParams.sessionId : "")
                    + ',' + ((searchParams.agent) ? searchParams.agent : "" )
                    + ',' + ((searchParams.target) ? searchParams.target : "" )
                    + ',' + ((searchParams.start) ? searchParams.start : "" )
                    + ',' + ((searchParams.end) ? searchParams.end : "");
                return (query === ",,,,") ? null : query;
            }
        }

        function parseChannels(channels) {
            if (!channels) {
                return [];
            }
            return channels.split(',');
        }
    }
})();
