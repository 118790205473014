(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('SettingController', SettingController);

    SettingController.$inject = ['$scope', '$state', 'Setting'];

    function SettingController($scope, $state, Setting) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.smsContent = {};
        vm.smsFrom = {};
        vm.tenantLanguage = {};
        vm.expirationTime = {};
        vm.mailDomain = {};
        vm.sessionDomain = {};
        vm.callbackUrl = {};
        vm.captchaEnabled = {};
        vm.success = null;
        vm.captchaEnabledOptions = ['true', 'false'];

        loadAll();

        function loadAll() {
            Setting.query(function (result) {
                for (var i = 0; i < result.length; i++) {
                    if (result[i].settingKey === 'smsContent') {
                        vm.smsContent = result[i];
                    } else if (result[i].settingKey === 'smsFrom') {
                        vm.smsFrom = result[i];
                    } else if (result[i].settingKey === 'tenantLanguage') {
                        vm.tenantLanguage = result[i];
                    } else if (result[i].settingKey === 'expirationTime') {
                        vm.expirationTime = result[i];
                        vm.expirationTime.settingValue = Number(vm.expirationTime.settingValue);
                    } else if (result[i].settingKey === 'mailDomain') {
                        vm.mailDomain = result[i];
                    } else if (result[i].settingKey === 'sessionDomain') {
                        vm.sessionDomain = result[i];
                    } else if (result[i].settingKey === 'callbackUrl') {
                        vm.callbackUrl = result[i];
                    } else if (result[i].settingKey === 'captchaEnabled') {
                        vm.captchaEnabled = result[i];
                    }
                }
            });
        }

        function save() {
            var settings = [vm.smsContent, vm.smsFrom, vm.tenantLanguage, vm.expirationTime, vm.mailDomain, vm.sessionDomain, vm.callbackUrl, vm.captchaEnabled];
            Setting.update(settings, function () {
                vm.error = null;
                vm.success = 'OK';
            }, function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
