(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', '$scope', 'Principal', '$state', '$location', '$timeout', '$uibModal', 'Auth', '$translate', 'VERSION', 'Captcha', 'GOOGLE_RECAPTCHA_KEY'];

    function HomeController($rootScope, $scope, Principal, $state, $location, $timeout, $uibModal, Auth, $translate, VERSION, Captcha, GOOGLE_RECAPTCHA_KEY) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.requestResetPassword = requestResetPassword;
        vm.authenticationError = false;
        vm.captchaError = false;
        vm.reCaptchaResponse = null;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.tenant = extractTenantId();
        vm.username = null;
        vm.clear = clear;
        vm.version = VERSION;
        console.log("SuperChannel Version: " + vm.version);
        vm.dataSiteKey = GOOGLE_RECAPTCHA_KEY ? GOOGLE_RECAPTCHA_KEY : 'no-key';
        vm.isCaptchaEnabled = null;
        getCaptchaEnabled();

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        $timeout(function () {
            angular.element('#username').focus();
        });

        function getCaptchaEnabled() {
            var captchaRequest = Captcha.get(vm.tenant);
            captchaRequest.$promise.then(function (content) {
                vm.isCaptchaEnabled = content.captchaEnabled;
            })
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated()) {
                    $translate.use(account.langKey).then(function () {
                        $translate.refresh();
                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                            $state.go(previousState.name, previousState.params);
                        } else {
                            if (vm.account.authorities.indexOf('ROLE_AGENT') > -1) {
                                $state.go('session');
                            } else if (vm.account.authorities.indexOf('ROLE_SUPERVISOR') > -1) {
                                $state.go('statistics');
                            } else if (vm.account.authorities.indexOf('ROLE_ADMIN') > -1) {
                                $state.go('user-management');
                            } else if (vm.account.authorities.indexOf('ROLE_SUPERADMIN') > -1) {
                                $state.go('jhi-metrics');
                            }
                        }
                    });
                }
            });
        }

        function login() {
            //Check grecaptcha only when enabled
            if (vm.isCaptchaEnabled === true) {
                vm.reCaptchaResponse = grecaptcha.getResponse();
            }

            if (vm.isCaptchaEnabled === true && vm.reCaptchaResponse && vm.reCaptchaResponse.length === 0) {
                vm.captchaError = true;
            } else {
                Auth.login({
                    username: vm.username,
                    password: vm.password,
                    tenant: vm.tenant,
                    rememberMe: vm.rememberMe,
                    reCaptcha: vm.reCaptchaResponse
                }).then(
                    function () {
                        vm.authenticationError = false;
                        $rootScope.$broadcast('authenticationSuccess');
                    }
                ).catch(
                    function (err) {
                        vm.authenticationError = true;
                        if (err.status === 400 && err.data.hasOwnProperty("ExpiredPassword")) {
                            vm.authenticationError = false;
                            open({
                                username: vm.username,
                                password: vm.password,
                                tenant: vm.tenant
                            });
                        }
                        if (err.data.hasOwnProperty("reCaptchaException")) {
                            vm.authenticationError = false;
                            vm.captchaError = true;
                        }
                        grecaptcha.reset();
                    });
            }
        }

        function newPassword(credentials) {
            vm.password = credentials.password;
            login();
        }


        function requestResetPassword() {
            $state.go('requestReset');
        }

        function extractTenantId() {
            var url = $location.url();
            if (url.indexOf('/') < 0)
                return null;
            else
                return url.split('/')[1];
        }

        function clear() {
            vm.authenticationError = false;
            vm.captchaError = null;
        }

        function open(credentials) {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/expiredPassword/expired-password.html',
                controller: 'ExpiredPasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }],
                    credentials: [function () {
                        return credentials;
                    }]
                }
            }).result.then(newPassword);
        }

    }
})();

