(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('ChannelDetailController', ChannelDetailController);

    ChannelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Channel'];

    function ChannelDetailController($scope, $rootScope, $stateParams, previousState, entity, Channel) {
        var vm = this;

        vm.channel = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('superChannelApp:channelUpdate', function(event, result) {
            vm.channel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
