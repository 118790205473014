(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('session', {
                parent: 'entity',
                url: '/session?page&sort&search',
                data: {
                    authorities: ['ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/session/sessions.html',
                        controller: 'SessionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'start,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: PaginationUtil.parseSearch($stateParams.search)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('session');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('session-detail', {
                parent: 'entity',
                url: '/session/{id}',
                data: {
                    authorities: ['ROLE_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/session/session-detail.html',
                        controller: 'SessionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('session');
                        $translatePartialLoader.addPart('item');
                        $translatePartialLoader.addPart('event');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Session', function ($stateParams, Session) {
                        return Session.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: 'session',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('sessionnew', {
                parent: 'session',
                url: '/new?target&channels&send&externalId',
                data: {
                    authorities: ['ROLE_AGENT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'PaginationUtil', function ($stateParams, $state, $uibModal, PaginationUtil) {
                    $uibModal.open({
                        templateUrl: 'app/entities/session/session-dialog.html',
                        controller: 'SessionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    target: $stateParams.target,
                                    selectedChannels: PaginationUtil.parseChannels($stateParams.channels),
                                    send: $stateParams.send,
                                    externalId: $stateParams.externalId
                                };
                            }
                        }
                    }).result.then(function (id) {
                        $state.go('session-detail', {id: id});
                    }, function () {
                        $state.go('session');
                    });
                }]
            });
    }

})();
