(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'Captcha', 'GOOGLE_RECAPTCHA_KEY'];

    function RequestResetController($timeout, Auth, Captcha, GOOGLE_RECAPTCHA_KEY) {
        var vm = this;

        vm.error = null;
        vm.errorEmailNotExists = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;
        vm.clear = clear;
        vm.dataSiteKey = GOOGLE_RECAPTCHA_KEY;
        vm.isCaptchaEnabled = null;
        getCaptchaEnabled();

        $timeout(function () {
            angular.element('#email').focus();
        });

        function clear() {
            vm.error = null;
            vm.errorEmailNotExists = null;
            vm.captchaError = null;
            vm.success = null;
        }

        function getCaptchaEnabled() {
            var captchaRequest = Captcha.get(vm.tenant);
            captchaRequest.$promise.then(function (content) {
                vm.isCaptchaEnabled = content.captchaEnabled;
            })
        }

        function requestReset() {

            vm.reCaptchaResponse = grecaptcha.getResponse();

            vm.error = null;
            vm.errorEmailNotExists = null;
            vm.captchaError = null;

            if (vm.reCaptchaResponse.length === 0 && vm.isCaptchaEnabled === true) {
                vm.captchaError = true;
            } else {
                Auth.resetPasswordInit(
                    {
                        email: vm.resetAccount.email,
                        reCaptcha: vm.reCaptchaResponse
                    }
                ).then(function () {
                    vm.success = 'OK';
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'e-mail address not registered') {
                        vm.errorEmailNotExists = 'ERROR';
                    } else if (response.status === 401 && response.data === 'reCaptcha Exception') {
                        vm.captchaError = true;
                        vm.success = null;
                    } else {
                        vm.error = 'ERROR';
                    }
                    grecaptcha.reset();
                });
            }
        }
    }
})();
