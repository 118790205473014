/**
 * Service to retrieve from the URL the tenant
 * the front-end application is handling.
 * Created by Jorge on 20/12/16.
 */
(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('TenantService', TenantService);

    TenantService.$inject = ['$location'];

    function TenantService($location) {

        var service = {
            getFromUrl: getFromUrl
        };

        return service;

        function getFromUrl() {
            var url = $location.url();
            if (url.indexOf('/') < 0)
                return null;
            else
                return url.split('/')[1];
        }
    }
})();
