(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'TenantService'];

    function NavbarController($state, $scope, Auth, Principal, ProfileService, TenantService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.currentTenant = TenantService.getFromUrl();
        vm.$state = $state;

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.login = account.login;
                }
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

    }
})();
