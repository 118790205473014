(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = ['Statistics', 'AlertService', '$sce', '$scope'];

    function StatisticsController(Statistics, AlertService, $sce, $scope) {
        var vm = this;
        var letters = '0123456789ABCDEF';
        var chart = null;

        vm.agents = [];
        vm.result = null;
        vm.series = [];

        vm.colors = vm.colorsByAgent = [];
        vm.labels = vm.labelsForTooltips = [];

        vm.multiple = {};
        vm.multiple.selected = [];
        vm.total = 0;

        vm.intervals = ["lastWeek", "lastMonth", "custom"];
        vm.interval = "lastMonth";

        vm.granularities = ["HOUR", "DAY", "MONTH"];
        vm.granularity = "DAY";

        vm.groupBy = '';
        vm.filterBy = [];

        vm.params = null;
        vm.fromGranularity = false;

        vm.changeInterval = changeInterval;
        vm.changeGranularity = changeGranularity;
        vm.getChart = getChart;

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startPicker = false;
        vm.datePickerOpenStatus.endPicker = false;

        vm.startPicker = {
            date: generateLastWeekDate(),
            datepickerOptions: {
                maxDate: null
            }
        };
        vm.endPicker = {
            date: new Date(),
            datepickerOptions: {
                minDate: null
            }
        };

        loadAgents();
        changeInterval();

        function loadAgents() {
            Statistics.agents({}, onSuccess, onError);

            function onSuccess(response) {
                for (var i = 0; i < response.length; i++) {
                    vm.agents.push({name: response[i]});
                    vm.colorsByAgent.push({agent: response[i], color: getColor()});
                }
                vm.colors = [];
                getChart();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getChart() {
            if (!vm.fromGranularity) {

                vm.startPicker.date.setUTCHours(0, 0, 0, 0);
                vm.endPicker.date.setUTCHours(23, 59, 59, 999);

                vm.params = {
                    "granularity": vm.granularity,
                    "startDate": vm.startPicker.date,
                    "endDate": vm.endPicker.date
                };

                if (vm.groupBy == "AGENT") {
                    $.extend(vm.params, {groupBy: vm.groupBy});
                }

                if (vm.multiple.selected.length > 0) {
                    var agents = [];
                    vm.multiple.selected.forEach(function (elem) {
                        agents.push(elem.name);
                    });
                    $.extend(vm.params, {filterBy: agents});
                }
            }

            vm.result = null;
            vm.series = [];
            vm.labels = [];
            vm.labelsForTooltips = [];

            Statistics.chart(vm.params, onSuccess, onError);

            function onSuccess(result) {
                vm.fromGranularity = false;
                if (result !== null) {
                    vm.result = result;
                    vm.granularity = result.granularity;
                    vm.fromGranularity = false;
                    buildData();
                }
            };

            function onError(error) {
                vm.fromGranularity = false;
                vm.params = null;
                AlertService.error(error.data.message);
                vm.params = null;
            };
        };

        function buildData() {
            if (vm.groupBy === '') {
                vm.result.values.forEach(function (elem) {
                    //labels
                    var label = generateLabel(elem);

                    if (vm.series.length === 0) {
                        vm.series.push({"label": label, "data": [elem.total]});
                    } else {
                        vm.series[0].data.push(elem.total);
                    }

                });

                if (vm.colors.length === 0) {
                    vm.colors.push(getColor());
                }
                if (vm.series.length > 0) {
                    //Made like this due to error in test with PhantomJS
                    var colorArr = [];
                    for (var i = 0; i < vm.series[0].data.length; i++) {
                        colorArr.push(vm.colors[0]);
                    }
                    $.extend(vm.series[0], {"backgroundColor": colorArr});
                }
            } else {
                vm.result.values.forEach(function (elem) {
                    //Agents who have sessions in this period.
                    for (var agent in elem.groups) {
                        setElement(agent, elem.groups);
                    }

                    //Agents who have no sessions in this period, so the chart should show a 0.
                    var arr = vm.result.groups.filter(function (item) {
                        return !elem.groups.hasOwnProperty(item);
                    });
                    for (var j = 0; j < arr.length; j++) {
                        var agent = {};
                        agent[arr[j]] = 0;
                        setElement(arr[j], agent);
                    }
                });

                //Labels
                vm.result.values.forEach(function (elem) {
                    generateLabel(elem);
                });

                //colors
                for (var i = 0; i < vm.colorsByAgent.length; i++) {
                    for (var j = 0; j < vm.series.length; j++) {
                        if (vm.colorsByAgent[i].agent === vm.series[j].label) {
                            $.extend(vm.series[j], {"backgroundColor": new Array(vm.series[0].data.length).fill(vm.colorsByAgent[i].color)});
                        }
                    }
                }
            }

            vm.totalPerRow = [];
            vm.totalPerColumn = [];

            if (vm.series.length > 0) {
                //Made like this due to error in test with PhantomJS
                for (var i = 0; i < vm.series[0].data.length; i++) {
                    vm.totalPerColumn.push(0);
                }
            }

            vm.series.forEach(function (elem) {

                //Total per column
                for (var i = 0; i < elem.data.length; i++) {
                    vm.totalPerColumn[i] = (vm.totalPerColumn[i] + parseInt(elem.data[i]));
                }

                //Total per row
                vm.totalPerRow.push(elem.data.reduce(function (total, num) {
                    return total + parseInt(num);
                }, 0));

            });

            //Total of totals
            vm.total = vm.totalPerColumn.reduce(function (total, num) {
                return total + parseInt(num);
            }, 0);

            if (chart != null) {
                chart.destroy();
            }

            //This is necessary due to problems with hover events in chart.js
            $('#chart').remove();
            $('#chart_container').append('<canvas id="chart" class="chart chart-bar" chart-legend="true"></canvas>');
            //

            var ctx = document.getElementById("chart");
            chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: vm.labels,
                    labelsForTooltips: vm.labelsForTooltips,
                    datasets: vm.series
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: (vm.groupBy !== '')
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: function (tooltipItems) {
                                return tooltipItems.yLabel;
                            }
                        }
                    }
                }
            });
        }

        function setElement(agent, value) {
            var elem = vm.series.filter(function (item) {
                return item.label === agent
            });
            if (elem.length === 0) {
                vm.series.push({"label": agent, "data": [value[agent]]});
            } else {
                elem[0].data.push(value[agent]);
            }
        }

        function generateStartOfDay() {
            var startOfDay = new Date();
            startOfDay.setHours(6);
            startOfDay.setMinutes(0);
            startOfDay.setSeconds(0);
            startOfDay.setMilliseconds(1);
            return startOfDay;
        }

        function generateLastWeekDate() {
            var d = new Date();
            d.setDate(d.getDate() - 7);
            return d;
        }

        function generateLastMonthDate() {
            var d = new Date();
            d.setMonth(d.getMonth() - 1);
            return d;
        }

        function getColor() {
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        function generateLabel(elem) {
            var label = "";
            var tooltipLabel = "";
            switch (vm.granularity) {
                case "HOUR":
                    var time = elem.timePeriod.start.split('T')[1];
                    var hour = time.split(":")[0];
                    var date = elem.timePeriod.start.split('T')[0];
                    var day = date.split('-')[2];
                    var month = date.split('-')[1];
                    label = (day + '/' + month + ' ' + hour + ":00");
                    tooltipLabel = (date + ' ' + hour + ":00");
                    break;
                case "DAY":
                    var date = (elem.timePeriod.start.split('T')[0]);
                    label = tooltipLabel = date.split('-').reverse().join('/');
                    break;
                case "MONTH":
                    var date = elem.timePeriod.start.split("T")[0];
                    var list = date.split("-");
                    label = tooltipLabel = (list[1] + "/" + list[0]);
                    break;
            }
            //vm.labelsForTooltips.push(tooltipLabel);
            vm.labels.push(label);
            return label;
        }

        function openCalendar(picker) {
            vm.datePickerOpenStatus[picker] = true;
        };

        function changeGranularity(granularity) {
            vm.granularity = granularity;
            if (vm.series != []) {
                vm.params.granularity = granularity;
                vm.fromGranularity = true;
                getChart();
            }
        }

        function changeInterval() {
            var d = new Date();

            switch (vm.interval) {
                case "lastWeek":
                    vm.startPicker.date = generateLastWeekDate();
                    break;
                case "lastMonth":
                    vm.startPicker.date = generateLastMonthDate();
                    break;
                default:
                    vm.startPicker.date = generateStartOfDay();
                    break;
            }
            vm.endPicker.date = d;
        };

        var watchMinMaxValues = $scope.$watch(function () {
            return [vm.startPicker, vm.endPicker];
        }, function () {
            vm.startPicker.datepickerOptions.maxDate = vm.endPicker.date;
            vm.endPicker.datepickerOptions.minDate = vm.startPicker.date;
        }, true);

        $scope.trustAsHtml = function (value) {
            return $sce.trustAsHtml(value);
        };

        $scope.$on('$destroy', function () {
            watchMinMaxValues();
        });
    }
})();
